<script lang="ts">
  import { NEWSCDN_URL } from '@config/endpoints'
  import type { ArticleType, LangType } from '@customTypes/newscraft'
  import type { ui } from '@i18n/ui'
  import { useTranslations } from '@i18n/utils'
  import getErrorMessage from '@utils/getErrorMessage'
  import axios from 'axios'
  import { Tabs, TabItem } from 'flowbite-svelte'
  import { onMount } from 'svelte'
  import Icon from '@iconify/svelte'

  export let lang: LangType

  type DataType = { label: keyof (typeof ui)[LangType]; data: ArticleType[] | undefined }[]

  const t = useTranslations(lang)

  let mostReadData: ArticleType[] | undefined
  let mostCommentedData: ArticleType[] | undefined

  let compiledData: DataType

  onMount(async () => {
    mostReadData = await fetchData('most_read_newslight')
    mostCommentedData = await fetchData('most_comment')

    compiledData = [
      { label: 'mostRead', data: mostReadData },
      { label: 'mostCommented', data: mostCommentedData }
    ]

    async function fetchData(feed: string): Promise<ArticleType[] | undefined> {
      try {
        const res = await axios(`${NEWSCDN_URL}/${lang === 'ms' ? 'my' : lang}/${feed}.json`)
        return res.data.data.slice(0, 5)
      } catch (error: unknown) {
        console.log(getErrorMessage(error))
      }
    }
  })
</script>

<div class="my-10">
  <Tabs
    style="underline"
    contentClass="py-4"
  >
    {#if compiledData}
      {#each compiledData as data}
        {#if data.data && data.data.length > 0}
          <TabItem
            open={data.label === 'mostRead' ? true : false}
            title={t(data.label)}
            defaultClass="text-lg font-semibold"
          >
            <ol class="flex flex-col divide-y">
              {#each data.data as d, i (d.sid)}
                {@const { showFirstTag } = JSON.parse(d.meta)}
                <li class="flex items-center gap-x-2 py-3">
                  <span class="inline-flex flex-none basis-10 justify-center text-2xl font-bold italic">
                    {i + 1}
                  </span>
                  <a
                    href={`/${lang}/${d.category}/${d.sid}/`}
                    class="group flex-auto underline-offset-4 duration-300"
                  >
                    {#if showFirstTag}
                      <span class="block text-sm text-brandOrange">{d.tags[0]}</span>
                    {/if}

                    <h3 class="inline duration-300 group-hover:text-brandOrange group-hover:underline">
                      {d.title}
                    </h3>
                    {#if d.comment_count > 0}
                      <div class="inline-flex items-center justify-between gap-x-1 align-middle text-sm">
                        <Icon
                          icon="mdi:insert-comment"
                          class="inline size-4 text-brandBlue duration-300 group-hover:text-brandOrange"
                        />
                        {d.comment_count.toLocaleString()} <span class="sr-only">{t('commentCount')}</span>
                      </div>
                    {/if}
                  </a>
                </li>
              {/each}
            </ol>
          </TabItem>
        {/if}
      {/each}
    {/if}
  </Tabs>
</div>
